
import {Component, Mixins, Prop} from 'vue-property-decorator';
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class IndirilecekEvrakTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() hideDetails!: string | boolean;
  @Prop() rules!: string;
  @Prop() dense!: string | boolean;

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  items = [
    "Dosyaya Eklenen Son ... Evrak",
    "Adres Araştırması Talebi",
    "Adres Sorusturmasi",
    "Avukat Portal Genel Talebi",
    "Avukat Portal Tebligat Talebi",
    "Bankadan Gelen Cevap Yazısı",
    "Borca İtiraz Talebi",
    "Borçlu Eklenmesi Talebi",
    "İcra Dairesi Genel Yazı",
    "Kapalı E-Tebliğ Mazbatası",
    "Kapalı Tebligat",
    "Masraf Makbuzu",
    "PTT Tebligat Sorgulaması",
    "Reddiyat Beyanı",
    "Reddiyat makbuzu",
    "Tahsil Harcı Makbuzu(Sayman Mutemet Alındısı)",
    "Tahsilat makbuzu",
    "Takibin Dayanagi Belge",
    "Takip Talebi",
    "Tebliğ Mazbatası",
    "Tensip zaptı",
    "Vekalet Pulu Makbuzu",
    "Vekaletname",
    "Yetkisizlikle Kapatılan Dosyanın Gönderilmesi",
    "Ödeme İcra Emri"
  ];
}
