
import {Component, Vue} from "vue-property-decorator";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";
import EvrakIndirFormWizard from "@/components/forms/form-wizard/eicrapro/EvrakIndirFormWizard.vue";

@Component({
  components:{EvrakIndirFormWizard, UyapBagliDegilView}
})
export default class EvrakIndirView extends Vue{

}
